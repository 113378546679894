import { DefaultSeoProps } from "next-seo";

const config: DefaultSeoProps = {
  openGraph: {
    type: "website",
    url: "https://www.animenzpiano.com",
    siteName: "Animenz Piano Sheets",
  },
  twitter: {
    handle: "@Animenzzz",
    site: "@Animenzzz",
    cardType: "summary_large_image",
  },
  additionalLinkTags: [
    {
      rel: "icon",
      href: "/favicon.png",
    },
    {
      rel: "apple-touch-icon",
      href: "/apple-touch-icon",
    },
  ],
  languageAlternates: [
    {
      hrefLang: "en",
      href: "https://www.animenzpiano.com",
    },
    {
      hrefLang: "zh",
      href: "https://www.animenzpiano.com/zh",
    },
    {
      hrefLang: "ja",
      href: "https://www.animenzpiano.com/ja",
    },
  ],
};

export default config;
