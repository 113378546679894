import React, { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { HiCheck, HiChevronUpDown } from "react-icons/hi2";

export interface LineInputProps {
  className?: string;
  leftIcon?: React.ReactNode;
  placeholder?: string;
  value: string;
  onChange: (value: string) => void;
}

export const LineInput: React.FC<LineInputProps> = ({
  className,
  leftIcon,
  placeholder,
  value,
  onChange,
}) => {
  return (
    <div className={className}>
      <div className="flex h-[45px] flex-row items-center border-0 border-b border-primary-light bg-transparent text-primary-light focus:border-primary-light/70 focus:ring-0">
        {leftIcon && (
          <div className="flex flex-row items-center">{leftIcon}</div>
        )}
        <input
          className="w-full truncate border-0 bg-transparent p-0 pl-[8px] font-medium text-primary-light placeholder-primary-light/70 focus:border-primary-light focus:placeholder-primary-light/30 focus:outline-none focus:ring-0"
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
        />
      </div>
    </div>
  );
};

export interface LineListboxOption {
  name: string;
  label: string;
  default?: boolean;
}

export interface LineListboxProps {
  className?: string;
  disabled?: boolean;
  leftIcon?: React.ReactNode;
  direction?: "up" | "down";
  options: LineListboxOption[];
  value: string;
  onChange: (name: string) => void;
}

export const LineListbox: React.FC<LineListboxProps> = ({
  className,
  disabled,
  leftIcon,
  direction,
  options,
  value,
  onChange,
}) => {
  const defaultOption = options.find((o) => o.default);
  const selected = options.find((o) => o.name == value);
  return (
    <Listbox disabled={disabled} value={value} onChange={onChange}>
      <div className={className}>
        <div className="relative">
          <div
            className={
              disabled ? "text-primary-light/70" : "text-primary-light"
            }
          >
            <Listbox.Button
              className={
                "h-[45px] w-full rounded-none border-0 border-b bg-transparent focus:border-primary-light focus:outline-none focus:ring-0" +
                (disabled
                  ? "border-primary-light/70 text-primary-light/70"
                  : "border-primary-light text-primary-light")
              }
            >
              <div className="flex items-center">
                {leftIcon && (
                  <span className="pointer-events-none flex items-center">
                    {leftIcon}
                  </span>
                )}
                <div className="block grow truncate pl-[8px] text-left">
                  {disabled ? (
                    <span className="text-primary-light/50">
                      {defaultOption?.label}
                    </span>
                  ) : selected?.default ? (
                    <span className="text-primary-light/70">
                      {selected?.label}
                    </span>
                  ) : (
                    selected?.label
                  )}
                </div>
                <span className="pointer-events-none flex items-center">
                  <HiChevronUpDown className="h-5 w-5" aria-hidden="true" />
                </span>
              </div>
            </Listbox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options
              className={`
                absolute
                z-[1] mt-1 max-h-60 w-full py-1 ${
                  direction === "up" && "bottom-[45px]"
                }
                overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5
                focus:outline-none sm:text-sm
              `}
            >
              {options.map(({ name, label }) => (
                <Listbox.Option
                  key={name}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                    }`
                  }
                  value={name}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {label}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <HiCheck className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </div>
    </Listbox>
  );
};
