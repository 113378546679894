import { useTranslations } from "next-intl";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { MutableRefObject, useRef } from "react";
import { useIntercept } from "../util/hooks";
import { LineListbox } from "./Input";
import { HiOutlineGlobeAsiaAustralia } from "react-icons/hi2";

import { socials_general, socials_zh } from "../public/data";
import { shouldDisplayChineseLocale } from "../util/service";

const localeOptions = [
  { name: "en", label: "English (US)" },
  { name: "zh", label: "简体中文 (CN)" },
  { name: "ja", label: "日本語 (JP)" },
];

interface FooterProps {
  pressKit: string;
}

const Footer: React.FC<FooterProps> = ({ pressKit }) => {
  const router = useRouter();
  const { locale, locales } = router;
  const localeToUse = shouldDisplayChineseLocale(locale)
    ? "zh"
    : locale == "zh-tw"
    ? "en"
    : locale;

  const socials = localeToUse === "zh" ? socials_zh : socials_general;

  const changeLanguage = async (newLocal: string) => {
    if (newLocal !== localeToUse) {
      await router.push("/", "/", { locale: newLocal });
    }
  };

  const t = useTranslations("Footer");

  const footerRef = useRef() as MutableRefObject<HTMLDivElement>;

  useIntercept(footerRef, async () => {
    if (locales) {
      await Promise.all(
        locales.map(async (locale) => router.prefetch("/", "/", { locale }))
      );
    }
  });

  return (
    <div ref={footerRef} className="bg-[#040A16] py-[25px]">
      <div className="mx-auto max-w-[1300px]">
        <div className=" flex w-full flex-col justify-around px-10 text-primary-light md:flex-row">
          <div className="py-5 md:p-5">
            <ul>
              <p className="pb-6 font-libre-baskerville text-3xl font-semibold md:text-[32px]">
                Animenz
              </p>
              <div className="flex gap-8 pb-5 text-white">
                {socials.map((social, index) => {
                  const { icon, href } = social;
                  return (
                    <a
                      href={href}
                      key={index}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cursor-pointer text-2xl"
                    >
                      {icon}
                    </a>
                  );
                })}
              </div>
            </ul>
          </div>
          <div className="py-5 md:p-5">
            <ul>
              <p className="font-regular pb-6 font-libre-baskerville text-2xl">
                {t("Discover.title")}
              </p>
              <li className="cursor-pointer pb-2 font-light hover:underline">
                <Link
                  href={t("Discover.links.link")}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("Discover.links.video")}
                </Link>
              </li>
            </ul>
            <ul>
              <p className="font-regular whitespace-nowrap py-6 font-libre-baskerville text-2xl">
                {t("Contact.title")}
              </p>
              <li className="cursor-pointer pb-2 font-light">
                {t("Contact.links.email")}
              </li>
            </ul>
          </div>
          <div className="py-5 md:p-5">
            <ul>
              <p className="font-regular pb-6 font-libre-baskerville text-2xl">
                {t("Resources.title")}
              </p>
              <li className="cursor-pointer pb-2 font-light hover:underline">
                <Link href="/about">{t("Resources.links.about")}</Link>
              </li>
              <li className="cursor-pointer pb-2 font-light hover:underline">
                <Link href="/news">{t("Resources.links.news")}</Link>
              </li>
              <li className="cursor-pointer pb-2 font-light hover:underline">
                <Link href="/sheets">{t("Resources.links.sheets")}</Link>
              </li>
              <li className="cursor-pointer pb-2 font-light hover:underline">
                <Link href="/#videos">{t("Resources.links.featured")}</Link>
              </li>
              {/* <li className="cursor-pointer pb-2 font-light hover:underline">
                <Link href={pressKit} target="_blank" rel="noopener noreferrer">
                  {t("Resources.links.pressKit")}
                </Link>
              </li> */}
            </ul>
          </div>
          <div className="flex flex-col items-stretch justify-end py-7 font-light">
            <LineListbox
              leftIcon={<HiOutlineGlobeAsiaAustralia className="h-5 w-5" />}
              direction="up"
              options={localeOptions}
              value={localeToUse ?? ""}
              onChange={changeLanguage}
            />
            <p className="pt-2 text-right text-xs">{t("copyright")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
