import "../styles/globals.css";
// import type { AppContext, AppProps } from "next/app";
// import App from "next/app";
import { Lato, Libre_Baskerville } from "@next/font/google";
import Footer from "../components/Footer";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { NextIntlProvider } from "next-intl";

import SEO from "../next-seo.config";
import { DefaultSeo } from "next-seo";
// import { convertLocale, getPressKit } from "../util/service";
import PlausibleProvider from "next-plausible";
import { AppProps } from "next/app";

const lato = Lato({
  subsets: ["latin"],
  weight: ["100", "300", "400", "700"],
  style: ["normal", "italic"],
  variable: "--font-lato",
});

const libreBaskerville = Libre_Baskerville({
  subsets: ["latin"],
  weight: ["400", "700"],
  variable: "--font-libre-baskerville",
});

const queryClient = new QueryClient();

// type CustomAppProps = AppProps & {
//   pressKit: any;
// };

// export default function MyApp({
//   Component,
//   pageProps,
//   pressKit,
// }: CustomAppProps) {
export default function App({ Component, pageProps }: AppProps) {
  return (
    <PlausibleProvider
      domain="animenzpiano.com"
      selfHosted={true}
      customDomain="https://analytics.animenzpiano.com"
    >
      <DefaultSeo {...SEO} />
      <main
        className={`${lato.variable} ${libreBaskerville.variable} font-lato`}
      >
        <NextIntlProvider messages={pageProps.messages}>
          <QueryClientProvider client={queryClient}>
            <Component {...pageProps} />
          </QueryClientProvider>
          <Footer pressKit="" />
        </NextIntlProvider>
      </main>
    </PlausibleProvider>
  );
}

// MyApp.getInitialProps = async (context: AppContext) => {
//   const loc = convertLocale(context.ctx.locale);
//   const pageProps = await App.getInitialProps(context);
//   const res = await getPressKit(loc || "en");
//   const res = "";

//   return { ...pageProps, pressKit: res };
// };
