import { useCallback, useEffect, useMemo, useState } from "react";
import { getSheetList } from "./service";
import {
  GetNextPageParamFunction,
  QueryFunction,
  QueryKey,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { SheetData, StrapiListResult } from "../types";
import { useIntl } from "next-intl";

interface useDebounceProps {
  value: string;
  delay: number;
}

interface useLoadHookProps {
  loadMoreRef: any;
  fetchNextPage: any;
  hasNextPage: boolean;
}

interface useSheetQueryProps {
  queryYear: string;
  queryType: string;
  debouncedSearch: string;
  loc: string;
}

export function useDebouncedSheetQuery({
  queryYear,
  queryType,
  debouncedSearch,
  loc,
}: useSheetQueryProps) {
  const queryKey: QueryKey = [queryYear, queryType, debouncedSearch, loc];

  const queryFn: QueryFunction<StrapiListResult<SheetData>> = useCallback(
    async (ctx) => {
      const { queryKey, pageParam } = ctx;
      const [uploadYear, type, search] = queryKey as string[];
      return await getSheetList({ uploadYear, type, search, loc }, pageParam);
    },
    [loc]
  );

  const getNextPageParam: GetNextPageParamFunction<
    StrapiListResult<SheetData>
  > = useCallback((_, page) => {
    if (page.length === page[0].meta.pagination.pageCount) {
      return undefined;
    } else {
      return page.length + 1;
    }
  }, []);

  return useInfiniteQuery({ queryKey, queryFn, getNextPageParam });
}

export function useDebounce({ value, delay }: useDebounceProps) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    // Update debounced value after delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}

export function useLoadHook({
  loadMoreRef,
  fetchNextPage,
  hasNextPage,
}: useLoadHookProps) {
  const handleObserver = useCallback(
    (entries: any) => {
      const [target] = entries;
      if (target.isIntersecting) {
        fetchNextPage();
      }
    },
    [fetchNextPage]
  );

  useEffect(() => {
    const element = loadMoreRef.current;
    const option = { threshold: 0 };

    const observer = new IntersectionObserver(handleObserver, option);
    observer.observe(element);
    return () => observer.unobserve(element);
  }, [loadMoreRef, fetchNextPage, hasNextPage, handleObserver]);
}

export function useIntercept(componentRef: any, callback: () => void) {
  const handleObserver = useCallback(
    (entries: any) => {
      const [target] = entries;
      if (target.isIntersecting) {
        callback();
      }
    },
    [callback]
  );

  useEffect(() => {
    const element = componentRef.current;
    const option = { threshold: 0 };

    const observer = new IntersectionObserver(handleObserver, option);
    observer.observe(element);
    return () => observer.unobserve(element);
  }, [componentRef, handleObserver]);
}

export const useScrollY = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return scrollY;
};

export const useLocalDateString = (date?: string) => {
  const [formattedDate, setFormattedDate] = useState("");
  const intl = useIntl();

  useEffect(() => {
    const d = new Date(date ?? "");
    setFormattedDate(
      intl.formatDateTime(d, {
        year: "numeric",
        month: "long",
        day: "numeric",
      })
    );
  }, [date, intl]);

  return formattedDate;
};
