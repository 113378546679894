import {
  SiInstagram,
  SiYoutube,
  SiFacebook,
  SiTwitter,
  SiBilibili,
  SiSinaweibo,
} from "react-icons/si";

import { RiTaobaoFill } from "react-icons/ri";

export const socials_general = [
  {
    icon: <SiFacebook />,
    href: "https://www.facebook.com/Animenz",
  },
  {
    icon: <SiTwitter />,
    href: "https://twitter.com/Animenzzz",
  },
  {
    icon: <SiInstagram />,
    href: "https://www.instagram.com/animenz_official/",
  },
  {
    icon: <SiYoutube />,
    href: "https://www.youtube.com/c/Animenzzz",
  },
];

export const socials_zh = [
  {
    icon: <SiBilibili />,
    href: "https://space.bilibili.com/6075139",
  },
  {
    icon: <SiSinaweibo />,
    href: "https://weibo.com/animenzz",
  },
  {
    icon: <RiTaobaoFill />,
    href: "https://animenz.taobao.com/",
  },
];

// Video Sites
export const video_site = "https://www.youtube.com/c/Animenzzz";
export const video_site_zh = "https://space.bilibili.com/6075139";
