import axios from "axios";
import {
  BannerData,
  ContactFormData,
  NewsArticleData,
  StrapiListResult,
  StrapiSingleResult,
  SheetData,
  AboutData,
  AboutJourneyData,
  contactInfoData,
  PressKitData,
  VideoCarouselData,
  LandingImageCaourselData,
} from "../types";
import request from "./request";

// >>> Strapi API >>>

async function strapiGetList<T>(
  url: string,
  params?: Record<string, any>
): Promise<StrapiListResult<T>> {
  const response = await request.get<StrapiListResult<T>>(url, { params });
  return response.data;
}

async function strapiGetSingle<T>(
  url: string,
  params?: Record<string, any>
): Promise<StrapiSingleResult<T>> {
  const response = await request.get<StrapiSingleResult<T>>(url, { params });
  return response.data;
}

async function strapiPost<T>(
  url: string,
  payload?: Record<string, any>
): Promise<T> {
  try {
    const response = await request.post<T>(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (e: any) {
    throw new Error(
      e.response?.data?.message?.at(0)?.messages?.at(0)?.message ?? e.message
    );
  }
}

// <<< Strapi API <<<

// >>> Banner >>>

async function getBannerData(name: string): Promise<BannerData | null> {
  const res = await strapiGetSingle<BannerData>(`/api/banner`, {
    populate: name,
  });
  return res.data;
}

export async function getLandingImages(): Promise<string[]> {
  const data = await strapiGetList<LandingImageCaourselData>(
    `/api/landing-galleries`,
    {
      populate: "source",
      sort: "id:asc",
    }
  );
  return (
    data.data?.map((d) => d.attributes.source?.data.attributes.url ?? "") ?? []
  );
}

export async function getSEOImage(): Promise<string> {
  const data = await getBannerData("seo_index");
  return data?.attributes.seo_index?.data.attributes.url ?? "";
}

export async function getAboutBanner(): Promise<string> {
  const data = await getBannerData("about_banner");
  return data?.attributes.about_banner?.data.attributes.url ?? "";
}

export async function getNewsBanner(): Promise<string> {
  const data = await getBannerData("news_banner");
  return data?.attributes.news_banner?.data.attributes.url ?? "";
}

export async function getContactBanner(): Promise<string> {
  const data = await getBannerData("contact_banner");
  return data?.attributes.contact_banner?.data.attributes.url ?? "";
}

export async function getSheetBanner(): Promise<string> {
  const data = await getBannerData("sheets_banner");
  return data?.attributes.sheets_banner?.data.attributes.url ?? "";
}

// <<< Banner <<<

// >>> About >>>

export async function getAbout(locale: string): Promise<string> {
  const res = await strapiGetSingle<AboutData>(`/api/about`, {
    locale: locale,
  });
  return res.data?.attributes.Description ?? "";
}

export async function getAboutProfileImage(): Promise<string> {
  const res = await strapiGetSingle<AboutData>(`/api/about`, {
    populate: "ProfileImage",
  });
  return res.data?.attributes.ProfileImage?.data.attributes.url ?? "";
}

export async function getAboutJourney(locale: string): Promise<string[]> {
  const res = await strapiGetList<AboutJourneyData>(`/api/about-journeys`, {
    locale: locale,
    sort: "id:asc",
  });
  return res.data?.map((d) => d.attributes.Item) ?? [];
}

export async function getAboutSection(locale: string): Promise<string> {
  const res = await strapiGetSingle<AboutData>(`/api/about`, {
    locale: locale,
  });
  return res.data?.attributes.LandingDescription ?? "";
}

// <<< About <<<

// >>> News Articles >>>

export async function getNewsSlugList(locale: string): Promise<string[]> {
  const res = await strapiGetList<NewsArticleData>(`/api/news-articles`, {
    locale: locale,
    fields: "slug",
  });
  return res.data?.map((d) => d.attributes.slug!) ?? [];
}

export async function getPinnedNewsArticleList(
  locale: string
): Promise<NewsArticleData[]> {
  const res = await strapiGetList<NewsArticleData>(`/api/news-articles`, {
    locale: locale,
    fields: ["title", "overview", "publishedAt", "slug", "date"],
    populate: "cover",
    sort: "date:desc",
    filters: {
      pinned: {
        $eq: true,
      },
    },
  });
  return res.data ?? [];
}

export async function getNewsArticleList(
  locale: string,
  pageSize: number,
  page: number = 1
): Promise<StrapiListResult<NewsArticleData>> {
  return await strapiGetList<NewsArticleData>(`/api/news-articles`, {
    locale: locale,
    fields: ["title", "overview", "publishedAt", "slug", "date"],
    populate: "cover",
    sort: "date:desc",
    pagination: {
      pageSize,
      page,
    },
  });
}

export async function getNewsArticle(
  slug: string,
  locale: string
): Promise<NewsArticleData | null> {
  const res = await strapiGetList<NewsArticleData>(`/api/news-articles`, {
    filters: {
      slug: {
        $eq: slug,
      },
    },
    populate: ["cover", "seo.metaImage", "seo.metaSocial"],
    locale: locale,
  });
  return res.data?.at(0) ?? null;
}

// <<< News Articles <<<

// >>> Sheets >>>

interface SheetListFilter {
  uploadYear?: string;
  type?: string;
  search?: string;
  loc?: string;
}

export async function getSheetList(
  { uploadYear = "", type = "", search = "", loc = "en" }: SheetListFilter = {},
  page: number = 1
): Promise<StrapiListResult<SheetData>> {
  if (search !== "") {
    return await strapiGetList<SheetData>(`/api/sheet/fs/${loc}/${search}`);
  }
  return await strapiGetList<SheetData>(`/api/sheets`, {
    filters: {
      UploadYear: {
        $containsi: uploadYear,
      },
      Type: {
        $contains: type,
      },
    },
    sort: "createdAt:desc",
    pagination: {
      page,
    },
    locale: loc,
  });
}

export async function getSheetYears(): Promise<string[]> {
  const res = await strapiGetList<string>("/api/sheet/years");
  return res.data ?? [];
}

// <<< Sheets <<<

// >>> Contact Form >>>

export async function sendContactForm(
  data: ContactFormData
): Promise<{ error?: string }> {
  try {
    await strapiPost(`/api/contacts`, { data });
    return {};
  } catch (e: any) {
    return {
      error: e.message,
    };
  }
}

export async function getContactInfo(locale: string): Promise<string> {
  const res = await strapiGetSingle<contactInfoData>("/api/contact-page", {
    locale: locale,
  });
  return res.data?.attributes.contactInfo ?? "";
}

// <<< Contact Form <<<

// >>> Index - Video Carousel >>>

export async function getVideoCarousel(
  locale: string
): Promise<{ src: string; image: string }[]> {
  const res = await strapiGetList<VideoCarouselData>("/api/video-carousels", {
    locale: locale,
    populate: "thumbnail",
    sort: "createdAt:desc",
  });

  return (
    res.data?.map((d) => ({
      src: d.attributes.src ?? "",
      image: d.attributes.thumbnail?.data.attributes.url ?? "",
    })) ?? []
  );
}

// >>> Footer - Press Kit >>>

export async function getPressKit(locale: string): Promise<string> {
  const res = await strapiGetSingle<PressKitData>("/api/press-kit", {
    populate: "PDF",
    locale: locale,
  });

  return res.data?.attributes.PDF?.data.attributes.url ?? "";
}

// <<< Internationlization <<<

export function convertLocale(locale?: string): string {
  if (shouldDisplayChineseLocale(locale)) {
    locale = "zh-CN";
  } else if (locale === "ja") {
    locale = "ja-JP";
  } else {
    locale = "en";
  }
  return locale;
}

export function shouldDisplayChineseLocale(locale?: string): boolean {
  const chineseLocale = ["zh-CN", "zh-hk", "zh"];
  return chineseLocale.includes(locale ?? "");
}

// >>> Internationlization >>>

// >>> Video Embed >>>

export const TranscodeEmbed = (htmlString: string) => {
  const embedRegex = /<p>!!iframe!![^>]*<\/p>/g;
  const embedMatch = htmlString.match(embedRegex);
  if (embedMatch) {
    const embedUrlArray = embedMatch[0].match(/src="([^"]*)"/) ?? "";
    const embedUrl = embedUrlArray[1];
    const iframeElement = `<iframe src="${embedUrl}" frameborder="0" allow="accelerometer; width: 100%; height: 100%; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    htmlString = htmlString.replace(embedRegex, iframeElement);
  }

  return htmlString;
};

// <<< Video Embed <<<
